<template>
  <div class="equipment-document-wrapper">
    <van-nav-bar
      title="设备文档"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="equipment-document-form">
      <van-popover
        v-model="showPopover"
        trigger="click"
        :actions="actions"
        placement="right-start"
        @select="onSelect"
      >
        <template #reference>
          <span class="doctype">{{ text }} <van-icon name="arrow-down"/></span>
        </template>
      </van-popover>
      <span>共{{ total }}条数据</span>
    </div>
    <div class="equipment-document-box">
      <van-empty
        v-if="!loading && list.length == 0"
        :image="emptyImage"
        description="暂无数据"
      />
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <div
          v-for="item in list"
          :key="item.id"
          class="document-item"
          @click="onDetail(item)"
        >
          <span class="document-interval">{{ item.updateTime }}</span>
          <div class="document-title">{{ item.name }}</div>
          <div class="document-bottom">
            <span class="document-mode">{{ item.typeName }} </span>
            <span v-if="item.fileDTO" class="iconfont iconfont-fujian"></span>
            <span
              v-if="item.introduction"
              class="icondesc iconfont iconfont-jianjie1"
              name="description"
            />
          </div>
          <van-icon class="document-arrow" name="arrow" />
          <van-divider />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import {
  getEquipmentDocumentList,
  getEquipmentTips
} from "@/api/basics/equipment";
import equipmentMixin from "./equipmentMixin";
export default {
  mixins: [equipmentMixin],
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      text: "文档类型",
      showPopover: false,
      actions: [],
      query: {
        page: 1,
        size: 10,
        type: ""
      },
      list: [],
      total: 0,
      loading: false,
      finished: false
    };
  },
  created() {
    this.getEquipmentTips();
  },
  methods: {
    onDetail(item) {
      this.$router.push({
        name: "equipmentDocumentDetail",
        params: {
          detail: item
        }
      });
    },
    onSelect(v) {
      console.log("v", v);
      this.text = v.text;
      // 设置类型
      this.query.type = v.value;
      // 清空历史数据
      this.list = [];
      // 回归到第一页
      this.query.page = 1;
      // 取消已经完成的状态
      this.finished = false;
      // 开始加载数据
      this.onLoad();
    },
    onLoad() {
      this.getData();
    },
    getData() {
      this.getEquipmentDocumentList();
    },
    getEquipmentTips() {
      getEquipmentTips(4).then(res => {
        this.actions = res[0].map(a => {
          a.text = a.label;
          return a;
        });
      });
    },
    getEquipmentDocumentList() {
      this.loading = true;
      getEquipmentDocumentList(this.id, this.query)
        .then(res => {
          console.log("res", res);
          this.loading = false;
          this.list = this.list.concat(res.list);
          if (res.list.length < this.query.size) {
            this.finished = true;
          } else {
            this.query.page++;
          }
          this.total = res.total;
        })
        .catch(e => {
          this.loading = false;
          this.finished = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-document-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-document-form {
    height: 56px;
    border-bottom: 1px solid #e1e3e8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    font-size: 12px;
    color: #999;
    .doctype {
      padding: 6px;
      background-color: #f8f9f9;
      border-radius: 4px;
      color: #646464;
      display: flex;
      align-items: center;
    }
  }
  .equipment-document-box {
    flex: 1;
    overflow-y: auto;
    height: 0;
    padding-top: 14px;
    .document-item {
      position: relative;
      padding: 0 16px;
      .document-arrow {
        position: absolute;
        right: 16px;
        bottom: 18px;
        font-size: 18px;
      }
      .document-interval {
        position: absolute;
        top: 0;
        right: 16px;
        font-size: 12px;
        color: #aaaaaa;
      }
      .document-title {
        white-space: nowrap;
        overflow: hidden;
        padding-right: 100px;
        text-overflow: ellipsis;
      }
      .document-bottom {
        margin-top: 18px;
        display: flex;
        font-size: 12px;
        align-items: center;
        .document-mode {
          font-size: 12px;
          color: #676767;
          margin-right: 26px;
        }
        .iconfont-fujian,
        .icondesc {
          color: #1676ff;
          margin-right: 10px;
        }
        .icondesc {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
